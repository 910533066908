import ActivityFeed from "components/owner-app/activity-feed/ActivityFeed";
import UserActionLog from "components/owner-app/user-action-log/UserActionLog";
import { BbotTabs, Breadcrumbs, Tabs, TOPContainer } from "top-component-library";

const SystemLogsPage = (props) => {
  const { selectedCustomer, userInfo } = props;

  return (
    <TOPContainer>
      <Breadcrumbs name={"System Logs"} link={"/system-logs"} />

      <div className={"margin-bottom-2"}>
        <h2>System Logs</h2>
      </div>

      <BbotTabs>
        <Tabs.TabPane tab="Audit Trail" key="1">
          <h4>Audit Trail</h4>
          <p>View a log of actions performed by a user.</p>
          <UserActionLog customerId={selectedCustomer?.customer_id} userInfo={userInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Integration Activity Feed" key="2">
          <h4>Integrations Activity Feed</h4>
          <p>View possible issues with your integrations.</p>
          <ActivityFeed
            endpoint={"/owner/getFeed"}
            endpointParameters={{
              customer_id: selectedCustomer?.customer_id,
              feed_type: "pos",
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Printer Activity Feed" key="3">
          <h4>Printer Activity Feed</h4>
          <p>View possible issues with your printer(s).</p>
          <ActivityFeed
            endpoint={"/owner/getFeed"}
            endpointParameters={{
              customer_id: selectedCustomer?.customer_id,
              feed_type: "print_",
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Financial Activity Feed" key="4">
          <h4>Financial Activity Feed</h4>
          <p>View financial activity and related issues.</p>
          <ActivityFeed
            endpoint={"/owner/getFeed"}
            endpointParameters={{
              customer_id: selectedCustomer?.customer_id,
              feed_type: "finance",
            }}
          />
        </Tabs.TabPane>
      </BbotTabs>
    </TOPContainer>
  );
};

export default SystemLogsPage;
