import axios from "axios";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { Component } from "react";
import { Form as BtpForm, Button, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { generalErrorAlert } from "../../../../util/Utils";
import { CheckboxInput, NumberInput, SearchSelectInput, TextInput } from "../../../global/form";
import RadioGroupInput from "../../../global/form/RadioGroupInput";

class SquareIntegrationModal extends Component {
  state = {
    squareConnected: false,
    injectOrders: false,
    treatVariantsAsModifiers: true,
    importImages: true,
    allLocations: [],
    selectedLocation: null,
    deleteMenuItems: false,
    showEditModal: false,
    displayLocationOnTicket: false,
    displayFulfillmentOnTicket: false,
    displayCheckoutInfoOnTicket: false,
    ticketNameTemplateString: null,
  };

  async componentDidMount() {
    await this.getSquareOptions();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      await this.getSquareOptions();
    }
  }

  getSquareOptions = async () => {
    try {
      const res = await axios.get("/admin/getAllSquareConfigOptions");
      this.setState({
        squareConnected: res.data.square_connected,
        injectOrders: res.data.inject_orders,
        treatVariantsAsModifiers: res.data.treat_variants_as_modifiers ?? true,
        importImages: res.data.import_images,
        allLocations: res.data.all_locations,
        selectedLocation: {
          value: res.data.selected_location,
          label: res.data.all_locations.find((l) => l.id === res.data.selected_location)?.["name"],
        },
        locationId: res.data.selected_location,
        ticketNameTemplateString: res.data.ticket_name_template_string,
        displayLocationOnTicket: res.data.display_location_on_ticket,
        displayFulfillmentOnTicket: res.data.display_fulfillment_on_ticket,
        displayCheckoutInfoOnTicket: res.data.display_checkout_info_on_ticket,
        autoAcceptOrdersInBbot: res.data.auto_accept_orders_in_bbot,
        syncFulfillmentStatusFromSquare: res.data.sync_fulfillment_status_from_square,
        typicalPrepMinutes: res.data.typical_prep_minutes,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble getting options from Square.");
    }
  };

  onHide = async (reloadOptions = true) => {
    const { onHide } = this.props;
    this.setState(
      {
        deleteMenuItems: false,
        showEditModal: false,
      },
      onHide
    );
    if (reloadOptions) {
      await this.getSquareOptions();
    }
  };

  sendOAuthRequest = async () => {
    const { selectedCustomer, onSave } = this.props;
    let onHide = this.onHide;
    let finishOAuthRequest = this.finishOAuthRequest;

    try {
      const res = await axios.get("/owner/start_square_oauth?customer_id=" + selectedCustomer.customer_id);
      let authWindow = window.open(res.data.url, "myWindow");
      var pollTimer = window.setInterval(async function () {
        // !== is required for compatibility with Opera
        if (authWindow.closed !== false) {
          window.clearInterval(pollTimer);
          onSave();
          await onHide();
          await finishOAuthRequest();
        }
      }, 200);
    } catch (error) {
      generalErrorAlert(error, "Trouble logging in to Square.");
    }
  };

  finishOAuthRequest = async () => {
    this.setState({
      showEditModal: true,
    });
  };

  sendSquareConfigRequest = async (values) => {
    const { selectedCustomer, onSave } = this.props;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        inject_orders: values.injectOrders,
        treat_variants_as_modifiers: values.treatVariantsAsModifiers,
        import_images: values.importImages,
        location_id: values.locationId,
        auto_accept_orders_in_bbot: values.autoAcceptOrdersInBbot,
        sync_fulfillment_status_from_square: values.syncFulfillmentStatusFromSquare,
        display_location_on_ticket: values.displayLocationOnTicket,
        display_fulfillment_on_ticket: values.displayFulfillmentOnTicket,
        display_checkout_info_on_ticket: values.displayCheckoutInfoOnTicket,
        ticket_name_template_string: values.ticketNameTemplateString,
        typical_prep_minutes: values.typicalPrepMinutes,
      };
      this.setState({ showLoader: true });
      await axios.post("/admin/setSquareConfig", payload);
      toast.success("Successfully configured Square");
      await this.onHide();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Trouble configuring Square.");
    } finally {
      this.setState({ showLoader: false });
    }
  };

  disconnect = async () => {
    const { selectedCustomer, onSave } = this.props;
    const { deleteMenuItems } = this.state;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        delete_menu_items: deleteMenuItems,
      };
      await axios.post("/admin/disconnectSquare", payload);
      await this.onHide(false);
      await onSave();
      await this.getSquareOptions();
      toast.success("Successfully disconnected from Square");
    } catch (error) {
      generalErrorAlert(error, "Trouble disconnecting from Square.");
    }
  };

  editModal = () => {
    const { show } = this.props;
    const {
      injectOrders,
      ticketNameTemplateString,
      importImages,
      treatVariantsAsModifiers,
      allLocations,
      locationId,
      selectedLocation,
      autoAcceptOrdersInBbot,
      syncFulfillmentStatusFromSquare,
      displayLocationOnTicket,
      displayFulfillmentOnTicket,
      displayCheckoutInfoOnTicket,
      typicalPrepMinutes,
    } = this.state;
    return (
      <Modal show={show || this.state.showEditModal} onHide={this.onHide} size={"lg"}>
        <Formik
          initialValues={{
            injectOrders: injectOrders ?? false,
            ticketNameTemplateString: ticketNameTemplateString ?? "",
            treatVariantsAsModifiers: treatVariantsAsModifiers ?? true,
            importImages: importImages ?? true,
            allLocations: allLocations || [],
            locationId: locationId || null,
            selectedLocation: selectedLocation || null,
            autoAcceptOrdersInBbot: autoAcceptOrdersInBbot ?? true,
            syncFulfillmentStatusFromSquare: syncFulfillmentStatusFromSquare ?? false,
            displayLocationOnTicket: displayLocationOnTicket ?? false,
            displayFulfillmentOnTicket: displayFulfillmentOnTicket ?? false,
            displayCheckoutInfoOnTicket: displayCheckoutInfoOnTicket ?? false,
            typicalPrepMinutes: typicalPrepMinutes ?? 20,
          }}
          onSubmit={this.sendSquareConfigRequest}
        >
          {({ values, setFieldValue }) => (
            <Form className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Configure Square Integration</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Row className={"margin-bottom-1"}>
                  <SearchSelectInput
                    isSearchable
                    id={"selectedLocation"}
                    md={12}
                    name={"selectedLocation"}
                    label={"Square Location"}
                    placeholder={"Select a location"}
                    defaultValue={selectedLocation}
                    value={selectedLocation}
                    options={allLocations.map((location) => ({
                      label: location.name,
                      value: location.id,
                    }))}
                    onChange={(value) => setFieldValue("locationId", value.value)}
                  />
                </Row>
                <Row className={"margin-bottom-1"}>
                  <RadioGroupInput
                    options={[
                      {
                        label: "Treat variations as modifiers",
                        value: true,
                      },
                      {
                        label: "Treat each variation as a separate menu item",
                        value: false,
                      },
                    ]}
                    label={"Should variations be treated as modifiers?"}
                    name={"treatVariantsAsModifiers"}
                    id={"treatVariantsAsModifiers"}
                    selectedValue={values.treatVariantsAsModifiers}
                    value={values.treatVariantsAsModifiers}
                    onChange={(value) => setFieldValue("treatVariantsAsModifiers", value === "true")}
                  />
                  <CheckboxInput
                    sm={12}
                    label={"Import images from Square"}
                    name={"importImages"}
                    id={"import-images"}
                  />
                  <CheckboxInput sm={12} label="Inject orders into Square" name="injectOrders" id="inject-orders" />
                  <CheckboxInput
                    sm={12}
                    label="Display TOP location on Square ticket"
                    name="displayLocationOnTicket"
                    id="display-location-on-ticket"
                    disabled={!values.injectOrders}
                  />
                  <CheckboxInput
                    sm={12}
                    label="Display TOP fulfillment method on Square ticket"
                    name="displayFulfillmentOnTicket"
                    id="display-fulfillment-on-ticket"
                    disabled={!values.injectOrders}
                  />
                  <CheckboxInput
                    sm={12}
                    label="Display TOP checkout info on Square ticket"
                    name="displayCheckoutInfoOnTicket"
                    id="display-checkout-info-on-ticket"
                    disabled={!values.injectOrders}
                  />
                  <CheckboxInput
                    sm={12}
                    label={
                      "Automatically mark orders Accepted by Staff in TOP by DoorDash, once they're injected into Square"
                    }
                    name={"autoAcceptOrdersInBbot"}
                    id={"auto-accept-orders-in-bbot"}
                    caption={
                      'TOP orders injected into Square will be set to "Accepted by Staff" in TOP for all ' +
                      "applicable Fulfillment Methods (which excludes Catering and no-feedback methods)"
                    }
                  />
                  <CheckboxInput
                    sm={12}
                    label={"Update TOP order status on order status updates in Square"}
                    name={"syncFulfillmentStatusFromSquare"}
                    id={"sync-fulfillment-status-from-square"}
                    caption={
                      'When Square orders are marked "In Progress", "Ready", or "Completed" in Square they will be updated in TOP to reflect that same status. ' +
                      'For example, pickup orders will be marked "Ready for pickup" in TOP when marked "Ready" in Square. ' +
                      "This can text the guest, just like setting the status in TOP would."
                    }
                  />
                </Row>
                <Row className={"margin-top-2 margin-bottom-2"}>
                  <TextInput
                    name={"ticketNameTemplateString"}
                    id={"ticket-name-template-string"}
                    label={[
                      "Ticket Name Field Format",
                      "  ",
                      <OverlayTrigger
                        key={"placement"}
                        placement={"right"}
                        overlay={
                          <Tooltip id={"tooltip-select-days-and-times"}>
                            <strong>
                              If you include a keyword below, it will be automatically replaced with the relevant info.
                            </strong>
                            <br />
                            $ORDER_NUMBER - Order number
                            <br />
                            $LOCATION - Location's name
                            <br />
                            $PATRON_NAME - Patron's name
                            <br />
                            $ROOM - Room number
                            <br />
                          </Tooltip>
                        }
                      >
                        <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                      </OverlayTrigger>,
                    ]}
                  />
                </Row>
                <Row>
                  <NumberInput
                    id={"typicalPrepMinutes"}
                    name={"typicalPrepMinutes"}
                    label={[
                      "Order Prep Time (minutes)",
                      "  ",
                      <OverlayTrigger
                        key={"placement"}
                        placement={"right"}
                        overlay={
                          <Tooltip id={"prep-time-tooltip"}>
                            This will control how soon before desired time future orders are printed, and how long after
                            asap orders are submitted that they are due.
                          </Tooltip>
                        }
                      >
                        <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                      </OverlayTrigger>,
                    ]}
                  />
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={this.onHide}
                  className={"margin-right-1"}
                  disabled={this.state.showLoader}
                >
                  Cancel
                </Button>
                {this.state.showLoader ? (
                  <Button size={"sm"} type={"submit"} disabled={true}>
                    <Loader props />
                  </Button>
                ) : (
                  <Button size={"sm"} type={"submit"}>
                    Save
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };

  disconnectOptions = () => (
    <div>
      <div className={"margin-bottom-1"}>
        <p>
          Disconnecting will stop orders from being sent to Square. If you would also like to delete menu items that
          came from Square, please check the checkbox below.
        </p>
      </div>
      <BtpForm.Group className={"margin-bottom-1 "}>
        <BtpForm.Check
          className={"check-box"}
          label={"Delete existing Square menu items"}
          type={"checkbox"}
          onChange={(event) => {
            this.setState({ deleteMenuItems: event.target.checked });
          }}
        />
      </BtpForm.Group>
    </div>
  );

  disconnectModal = () => {
    const { show } = this.props;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect From Square</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.disconnectOptions()}</Modal.Body>
        <Modal.Footer className={"space-between"}>
          <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
            Cancel
          </Button>
          <Button size={"sm"} variant={"danger"} onClick={this.disconnect}>
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  connectModal = () => {
    const { show } = this.props;
    const { squareConnected } = this.state;
    return squareConnected ? (
      this.editModal()
    ) : (
      <Modal show={show} onHide={this.onHide}>
        <Formik enableReinitialize initialValues={{}} onSubmit={async () => await this.sendOAuthRequest()}>
          {({ values, errors, touched, isValidating, setFieldValue }) => (
            <Form className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Integrate With Square</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <div className={"margin-bottom-1"}>
                  <p>To integrate with Square, first authorize the Square app to use TOP by clicking below.</p>
                  <p style={{ color: "red" }}>Warning: Connecting Square will delete all existing menu items.</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"}>
                  Connect
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };

  render() {
    const { disconnectMode } = this.props;
    return disconnectMode ? this.disconnectModal() : this.connectModal();
  }
}

SquareIntegrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  disconnectMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SquareIntegrationModal;
