import axios from "axios";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import Text from "antd/es/typography/Text";
import NetworkCard from "components/owner-app/cards/printer-configuration/NetworkCard";
import AddEditNetworkModal from "components/owner-app/modals/printer-configuration/AddEditNetworkModal";
import styled from "styled-components";
import { BbotButton, Breadcrumbs, Col, PageLoadingSpinner, TOPContainer } from "top-component-library";
import { generalErrorAlert } from "util/Utils";

const PrinterConfiguration = (props) => {
  const { selectedCustomer, userInfo, allowedCustomersById } = props;
  const [showAddEditNetworkModal, setShowAddEditNetworkModal] = useState(false);
  const [networks, setNetworks] = useState([]);
  const [availableCustomerHandheldConfigs, setAvailableCustomerHandheldConfigs] = useState([]);
  const [stations, setStations] = useState({});
  const [driverProfiles, setDriverProfiles] = useState([]);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAllCustomerNetworks = async () => {
    if (!selectedCustomer) {
      return;
    }
    try {
      const res = await axios.get("/printer-setup/network-subnets", {
        params: { customer_id: selectedCustomer.customer_id },
      });

      setNetworks(res.data.networks);
      setAvailableCustomerHandheldConfigs(res.data.available_handheld_configs);
      setDriverProfiles(res.data.driver_profiles);
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to server. Please refresh the page.");
      console.error(error);
    }
  };

  const groupStationsByCustomers = (stations) => {
    const isAdmin = userInfo?.role === "admin";
    let stationsByCustomerId = {};
    stations.forEach((station) => {
      if (stationsByCustomerId.hasOwnProperty(station.customer_id)) {
        stationsByCustomerId[station.customer_id].stations.unshift(station);
      } else {
        stationsByCustomerId[station.customer_id] = {
          customer_name: allowedCustomersById.hasOwnProperty(station.customer_id)
            ? isAdmin
              ? allowedCustomersById[station.customer_id].name_for_admin
              : allowedCustomersById[station.customer_id].name_for_owner
            : "",
          stations: [station],
        };
      }
    });
    return stationsByCustomerId;
  };

  const getStations = async () => {
    if (!selectedCustomer) {
      return;
    }
    try {
      const res = await axios.get("/owner/getAllowedCustomersStations");
      setStations(groupStationsByCustomers(res.data.allowed_customers_stations));
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to server. Please refresh the page.");
      console.error(error);
    }
  };

  const getNetworkData = async () => {
    setIsLoading(true);
    await getAllCustomerNetworks();
    await getStations();
    setNeedsRefresh(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getNetworkData();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (needsRefresh) {
      getNetworkData();
    }
  }, [needsRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TOPContainer>
      <div>
        <Breadcrumbs link={"/printer-configuration"} name={"Printer Configuration"} />
        <div className={"margin-bottom-3"}>
          <h2>
            Manage Printer Settings For <span className={"color-blue-5"}>{selectedCustomer?.customer_name}</span>
          </h2>
        </div>
        <div className={"margin-bottom-3"}>
          <div className={"color-blue-5"}>
            <p>
              <span>
                Use this page to monitor, connect, and disconnect printing devices for your system. Printers, Printer
                Control Boxes, and Printing Terminals are organized by their <b>Network</b>. Printer Control Boxes and
                Printing Terminals manage a printing queue, connect with a DoorDash server, and inject orders into
                existing printers.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={"margin-bottom-4"}>
        <BbotButton id={"add-network-button"} type={"primary"} onClick={() => setShowAddEditNetworkModal(true)}>
          Create a new Printing Network
        </BbotButton>
      </div>
      {isLoading && !networks.length ? (
        <Row>
          <Col span={2} offset={11}>
            <PageLoadingSpinner />
          </Col>
        </Row>
      ) : networks.length ? (
        <div>
          {networks.map((network) => (
            <Row className={"margin-bottom-2"} key={network.id}>
              <NetworkCard
                selectedCustomer={selectedCustomer}
                network={network}
                availableCustomerHandheldConfigs={availableCustomerHandheldConfigs}
                stations={stations}
                userInfo={userInfo}
                driverProfiles={driverProfiles}
                onSaveCallback={() => {
                  setNeedsRefresh(true);
                }}
              />
            </Row>
          ))}
        </div>
      ) : (
        <NoNetworkRow>
          <Text>This Customer is not configured on any Printing Networks</Text>
        </NoNetworkRow>
      )}
      <AddEditNetworkModal
        selectedCustomer={selectedCustomer}
        showAddEditNetworkModal={showAddEditNetworkModal}
        onSaveCallback={() => {
          setNeedsRefresh(true);
          setShowAddEditNetworkModal(false);
        }}
        onHideCallback={() => setShowAddEditNetworkModal(false)}
        userInfo={userInfo}
      />
    </TOPContainer>
  );
};

const NoNetworkRow = styled(Row)`
  justify-content: center;
`;

export default PrinterConfiguration;
