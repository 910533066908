import PropTypes from "prop-types";
import { Breadcrumbs, TOPContainer } from "top-component-library";

const OPContainer = ({
  title,
  selectedCustomer,
  url,
  description,
  userInfo,
  refreshOnCurrentPageClick = false,
  ...props
}) => {
  /* Returns the container for an OP app */
  return (
    <TOPContainer>
      {/*Breadcrumbs*/}
      <Breadcrumbs name={title} link={url} refreshOnCurrentPageClick={refreshOnCurrentPageClick} />
      {/*Header*/}
      <div className="margin-bottom-3">
        <h2>
          Manage {title} for{" "}
          <span className={"color-blue-5"}>
            {userInfo?.role === "admin" ? selectedCustomer?.name_for_admin : selectedCustomer?.name_for_owner}
          </span>
        </h2>
      </div>
      {/*Description*/}
      {description && (
        <div className="margin-bottom-3 color-blue-5">
          <p>{description}</p>
        </div>
      )}
      {/*Content*/}
      <div>{props.children}</div>
    </TOPContainer>
  );
};

export default OPContainer;

OPContainer.propTypes = {
  title: PropTypes.string.isRequired,
  selectedCustomer: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
  url: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  refreshOnCurrentPageClick: PropTypes.bool, // If true, clicking the current page in the breadcrumb will trigger
  // a refresh
};
