import { useCallback, useEffect, useState } from "react";
import {
  BbotAlert,
  BbotButton,
  BbotTable,
  BbotTabs,
  BbotTag,
  Breadcrumbs,
  Form,
  notification,
  Tabs,
  TOPContainer,
} from "top-component-library";

import axios from "axios";
import LocationEditorHelpLinks from "components/owner-app/locations/LocationEditorHelpLinks";
import AddLocationsModal from "components/owner-app/modals/location-editor/AddLocationsModal";
import DeleteLocationsModal from "components/owner-app/modals/location-editor/DeleteLocationsModal";
import SaveConfirmationModal from "components/owner-app/modals/location-editor/save-confirmation-modal/SaveConfirmationModal";
import TableMappingModal from "components/owner-app/modals/location-editor/TableMappingModal";
import BBOT_SERVER from "Config";
import {
  trackClickAddNewLocation,
  trackClickConfirmSaveLocationSettings,
  trackClickDeleteLocation,
  trackClickEditLocation,
  trackClickGoBackLocationEditor,
  trackClickOrderingEnabled,
  trackClickSaveLocationSettings,
} from "instrumentation/tracking/page-tracking-events";
import CheckoutTab from "pages/locations/CheckoutTab";
import FulfillmentTab from "pages/locations/FulfillmentTab";
import GeneralTab from "pages/locations/GeneralTab";
import MenusTab from "pages/locations/MenusTab";
import SharingTab from "pages/locations/SharingTab";
import StationsTab from "pages/locations/StationsTab";
import styled from "styled-components";
import { SettingsEditorContainer } from "styles/SettingsContainerStyles";
import BbotToggle from "top-component-library/BbotToggle";
import BulkEditHeader from "top-component-library/BulkEditHeader";
import { capitalizeString, generalErrorAlert, removeKeys, uniqueFilterFunc } from "util/Utils";

const LocationEditor = (props) => {
  const { selectedCustomer, userInfo } = props;

  const [locations, setLocations] = useState([]);
  const [forceAllowOrderAhead, setForceAllowOrderAhead] = useState(false);
  const [doordashLof, setDoordashLof] = useState(false);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [defaultLocationSettings, setDefaultLocationSettings] = useState({});
  const [updatedLocationSettings, setUpdatedLocationSettings] = useState({});
  const [fulfillmentMethodPrettyNames, setFulfillmentMethodPrettyNames] = useState({});
  const [menus, setMenus] = useState({});
  const [stations, setStations] = useState({});
  const [kioskConfigs, setKioskConfigs] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false);
  const [showTableMappingsModal, setShowTableMappingsModal] = useState(false);
  const [showAddLocationsModal, setShowAddLocationsModal] = useState(false);
  const [showDeleteLocationsModal, setShowDeleteLocationsModal] = useState(false);
  const [checkoutTabValid, setCheckoutTabValid] = useState(true);

  // integration for table/employee mapping
  const [posIntegration, setPosIntegration] = useState(null);
  const [posName, setPosName] = useState(null);

  // filters
  const [reportingZones, setReportingZones] = useState([]);

  // form instance
  const [checkoutTabForm] = Form.useForm();

  useEffect(() => {
    const alertUser = (e) => {
      if (Object.keys(updatedLocationSettings).length > 0) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [updatedLocationSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const getLocations = async () => {
    if (!selectedCustomer) return;

    setLoadingLocations(true);
    try {
      const res = await axios.get("api/ownerlocations", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      setMenus(res.data.menus);
      setStations(res.data.stations);
      setForceAllowOrderAhead(res.data.force_allow_order_ahead);
      setDoordashLof(res.data.doordash_lof);
      setLocations(res.data.locations);
      setKioskConfigs(res.data.kioskConfigs);
      setFulfillmentMethodPrettyNames(res.data.fulfillment_methods);

      // calculate reporting zones
      const zones = res.data.locations.map((location) => location.zone_for_reports).filter(uniqueFilterFunc);

      setReportingZones(zones);
    } catch (error) {
      notification.error({
        message: "There was an error getting the location settings. Redirecting to dashboard.",
      });
      console.error(error);
    }
    setLoadingLocations(false);
  };

  const getIntegrations = async () => {
    if (!selectedCustomer) return;

    try {
      const res = await axios.get("/owner/getAllPartnersAndIntegrations", {
        params: {
          customerId: selectedCustomer?.customer_id,
        },
      });
      const integrations = res.data.customer_integrations;

      if (Object.keys(integrations).includes("toast")) {
        setPosIntegration("toast");
        setPosName("Toast");
      } else if (Object.keys(integrations).includes("omnivore")) {
        setPosIntegration("omnivore");
        setPosName("Omnivore");
      } else if (Object.keys(integrations).includes("omnivore_direct")) {
        setPosIntegration("omnivore_direct");
        setPosName(integrations.omnivore_direct.pos_type);
      } else if (Object.keys(integrations).includes("omnivore_3")) {
        setPosIntegration("omnivore_3");
        setPosName(integrations.omnivore_3.pos_type);
      } else if (Object.keys(integrations).includes("deliverect")) {
        setPosIntegration("deliverect");
        setPosName("Deliverect");
      } else {
        setPosIntegration(null);
        setPosName(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setEditMode(false);
    setSelectedLocationIds([]);
    setDefaultLocationSettings({});
    setUpdatedLocationSettings({});
    setMenus({});
    setStations({});
    getLocations();
    getIntegrations();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateDefaultLocationSettings = (locations) => {
    if (locations.length === 0) return;

    const properties = Object.keys(locations[0]);
    let defaultSettings = {};
    properties.forEach((property) => {
      const locationProperties = locations.map((location) => location[property]);

      // annotate properties with counters
      const countedProperties = locationProperties
        .reduce((counter, property) => {
          const counterProperty = counter.find((counterEntry) => counterEntry.property === property);
          if (counterProperty) {
            // if property in counter, increase counter
            counterProperty.count += 1;
          } else {
            counter.push({ property: property, count: 1 });
          }
          return counter;
        }, [])
        .sort((propertyA, propertyB) => (propertyA.count < propertyB.count ? 1 : -1));
      defaultSettings[property] = countedProperties[0].property;
    });

    setDefaultLocationSettings(defaultSettings);
  };

  const getLocationData = useCallback(() => {
    // get the location data
    if (selectedLocationIds.length === 1) {
      const foundLocation = locations.find((location) => location.locationId === selectedLocationIds[0]);
      if (foundLocation) setDefaultLocationSettings(foundLocation);
    } else {
      // calculate the most common properties
      calculateDefaultLocationSettings(
        locations.filter((location) => selectedLocationIds.includes(location.locationId))
      );
    }
  }, [selectedLocationIds, locations]);

  useEffect(() => {
    getLocationData();
  }, [getLocationData, locations]);

  const shouldRevertMenusChanges = (settingsUpdates, category) => {
    return (
      category === "menus" &&
      ((Object.values(settingsUpdates)[0] === "add" &&
        defaultLocationSettings.menus.includes(Object.keys(settingsUpdates)[0])) ||
        (Object.values(settingsUpdates)[0] === "remove" &&
          !defaultLocationSettings.menus.includes(Object.keys(settingsUpdates)[0])))
    );
  };

  const shouldRevertStationsChanges = (settingsUpdates, category) => {
    return (
      category === "stations" &&
      ((Object.values(settingsUpdates)[0] === "add" &&
        defaultLocationSettings.stations.includes(Object.keys(settingsUpdates)[0])) ||
        (Object.values(settingsUpdates)[0] === "remove" &&
          !defaultLocationSettings.stations.includes(Object.keys(settingsUpdates)[0])))
    );
  };

  const shouldRevertPropertiesChanges = (settingsUpdates, category) => {
    return (
      (category === "properties" &&
        defaultLocationSettings[Object.keys(settingsUpdates)[0]] === Object.values(settingsUpdates)[0]) ||
      (defaultLocationSettings[Object.keys(settingsUpdates)[0]] === null && Object.values(settingsUpdates)[0] === "")
    );
  };

  /**
   * Updates the js object that contains the updates to bulk editor
   * @param settingsUpdates js object that update the bulk editor
   * @param category one of properties, menus, stations
   */
  const updateLocationSettings = (settingsUpdates, category) => {
    // if in singular edit mode and the new settings match the old settings, remove from tracking
    if (
      selectedLocationIds.length === 1 &&
      Object.keys(settingsUpdates).length === 1 &&
      (shouldRevertMenusChanges(settingsUpdates, category) ||
        shouldRevertStationsChanges(settingsUpdates, category) ||
        shouldRevertPropertiesChanges(settingsUpdates, category))
    ) {
      if (Object.keys(updatedLocationSettings).includes(category)) {
        // deconstruct the updatedLocationSettings
        const { [Object.keys(settingsUpdates)[0]]: temp, ...rest } = updatedLocationSettings[category];

        // save the new updatedLocationSettings
        setUpdatedLocationSettings({
          ...updatedLocationSettings,
          [category]: {
            ...rest,
          },
        });
      }
    } else {
      setUpdatedLocationSettings({
        ...updatedLocationSettings,
        [category]: {
          ...updatedLocationSettings[category],
          ...settingsUpdates,
        },
      });
    }
  };

  const updateCustomMenuProperties = (updatedProperties) => {
    setUpdatedLocationSettings({
      ...updatedLocationSettings,
      properties: {
        ...updatedLocationSettings.properties,
        custom_menu_properties: {
          ...updatedLocationSettings.properties?.custom_menu_properties,
          ...updatedProperties,
        },
      },
    });
  };

  const removeChanges = (properties, category) => {
    if (
      Object.keys(updatedLocationSettings).includes(category) &&
      Object.keys(updatedLocationSettings[category]).includes(properties[0])
    ) {
      const removedKeySettings = removeKeys(updatedLocationSettings[category], properties);
      if (Object.keys(removedKeySettings).length === 0) {
        // clear category from object
        const removedCategorySettings = removeKeys(updatedLocationSettings, [category]);
        setUpdatedLocationSettings(removedCategorySettings);
      } else {
        setUpdatedLocationSettings({
          ...updatedLocationSettings,
          [category]: removedKeySettings,
        });
      }
    }
  };

  const removeCustomMenuPropertyChanges = (properties) => {
    if (!updatedLocationSettings.properties?.custom_menu_properties) {
      // if no custom menu properties set already
      return;
    }

    const removedKeyCustomMenuProperties = removeKeys(
      updatedLocationSettings.properties.custom_menu_properties,
      properties
    );

    if (Object.keys(removedKeyCustomMenuProperties).length > 0) {
      setUpdatedLocationSettings((locationSettings) => ({
        ...locationSettings,
        properties: {
          ...locationSettings.properties,
          removedKeyCustomMenuProperties,
        },
      }));
    } else {
      removeChanges(["custom_menu_properties"], "properties");
    }
  };

  /**
   * Save the updates in the location editor to the backend.
   */
  const saveSettings = async () => {
    setIsSaving(true);
    try {
      const payload = {
        ...updatedLocationSettings,
        locationIds: selectedLocationIds,
        customer_id: selectedCustomer.customer_id,
      };
      await axios.post("api/ownerlocations", payload);
      // re-fetch location data, set default location settings
      await getLocations();

      // remove changes tracker variable
      setUpdatedLocationSettings({});

      notification.success({
        message: "Successfully saved location settings.",
      });
      setShowSaveConfirmationModal(false);
      await getLocations();
    } catch (error) {
      generalErrorAlert(error, "Trouble saving location settings.", selectedCustomer?.customer_id);
    }
    setIsSaving(false);
  };

  /**
   * Sends a request to the backend to toggle a location on/off
   * @param locationId Id of the location to toggle
   * @param locationName Name of the location to toggle. Used in success message.
   * @param locationEnabled Desired state of location.
   */
  const toggleLocation = async (locationId, locationName, locationEnabled) => {
    try {
      const payload = {
        properties: {
          order_allowed: locationEnabled ? "on" : "off",
        },
        locationIds: [locationId],
        customer_id: selectedCustomer.customer_id,
      };
      await axios.post("api/ownerlocations", payload);
      notification.success({
        message: `Successfully turned ${locationEnabled ? "on" : "off"} ${locationName}`,
      });
      // reload the locations
      const res = await axios.get("api/ownerlocations", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      setLocations(res.data.locations);
    } catch (error) {
      generalErrorAlert(
        error,
        "Could not toggle " + locationName + ". Please try again.",
        selectedCustomer?.customer_id
      );
    }
  };

  const userIsAdmin = userInfo?.role === "admin";
  // Used to guard parts of the page that should be TOP admin only.
  const userIsBbotAdmin = userInfo?.teams?.includes("bbot");

  const legacyLocationEditorUrl = userIsAdmin
    ? BBOT_SERVER + "/superuser/console/#!/edit/locations"
    : BBOT_SERVER + "/owner/console/#!/edit/locations";

  const locationsTable = () => {
    const locationsColumns = [
      {
        title: "Location Name/Code",
        key: "location_name_and_code",
        dataIndex: "location_name_and_code",
        sorter: {
          compare: (loc1, loc2) =>
            loc1.location_name_and_code.locationName.localeCompare(loc2.location_name_and_code.locationName),
        },
        render: (locationInfo) => (
          <div>
            <div data-test-id={"location-name"}>{locationInfo.locationName}</div>
            <div className={"text-small-detail"}>
              <span className={"margin-right-1"}>{locationInfo.locationShortId}</span>
              <span>{!!locationInfo.external_url && <BbotTag>External</BbotTag>}</span>
            </div>
          </div>
        ),
      },
      {
        title: "Zone",
        key: "zone",
        dataIndex: "zone",
        filters: reportingZones.map((reportingZone) => ({
          text: reportingZone,
          value: reportingZone,
        })),
        onFilter: (filterZone, location) => {
          return location.zone === filterZone;
        },
        sorter: {
          compare: (loc1, loc2) => loc1.zone.localeCompare(loc2.zone),
        },
      },
      {
        title: "Ordering Enabled",
        key: "ordering_allowed",
        dataIndex: "ordering_allowed",
        render: (location) => (
          <BbotToggle
            defaultEnabled={location.order_allowed === "on"}
            onClick={() => {
              trackClickOrderingEnabled({ enabled: !(location.order_allowed === "on") });
              toggleLocation(location.locationId, location.locationName, !(location.order_allowed === "on"));
            }}
          />
        ),
      },
      {
        title: "Fulfillment Method",
        key: "fulfillment_method",
        dataIndex: "fulfillment_method_pretty_name",
        filters: Object.entries(fulfillmentMethodPrettyNames).map(
          ([fulfillmentMethod, fulfillmentMethodPrettyName]) => ({
            text: fulfillmentMethodPrettyName,
            value: fulfillmentMethod,
          })
        ),
        onFilter: (value, location) => {
          return location.fulfillment_method.includes(value);
        },
        sorter: {
          compare: (loc1, loc2) => loc1.fulfillment_method.localeCompare(loc2.fulfillment_method),
        },
      },
    ];

    const locationsData = locations.map((location) => ({
      key: location.locationId,
      location_name_and_code: {
        locationName: location.locationName,
        locationShortId: location.locationShortId,
        external_url: location.external_url,
      },
      zone: location.zone_for_reports,
      ordering_allowed: location,
      fulfillment_method: location.fulfillment_method,
      fulfillment_method_pretty_name: fulfillmentMethodPrettyNames[location.fulfillment_method],
      external_url: location.external_url,
    }));

    const selectedLocations = locations.filter((location) => selectedLocationIds.includes(location.locationId));

    const countExternalLocationsSelected = selectedLocations.reduce(
      (count, location) => count + (!!location.external_url ? 1 : 0),
      0
    );
    const countNonExternalLocationsSelected = selectedLocations.length - countExternalLocationsSelected;

    const externalAndRegularLocationsSelected = !!countExternalLocationsSelected && !!countNonExternalLocationsSelected;
    const multipleExternalLocationsSelected = countExternalLocationsSelected > 1;

    const bothLocationTypesSelectedErrorMessage =
      externalAndRegularLocationsSelected &&
      "Cannot edit both external and non-external url locations. Please un-select external url locations to continue.";
    const multipleExternalLocationsSelectedErrorMessage =
      multipleExternalLocationsSelected &&
      "Cannot edit multiple external url locations at the same time. Please un-select additional locations to continue.";

    const invalidLocationSelectionErrorMessage =
      bothLocationTypesSelectedErrorMessage || multipleExternalLocationsSelectedErrorMessage || "";
    const invalidLocationSelection = !!invalidLocationSelectionErrorMessage;

    return (
      <div>
        <div className={"margin-bottom-2"}>
          {posIntegration && (
            <BbotButton type="primary" onClick={() => setShowTableMappingsModal(true)}>
              {capitalizeString(`Edit ${posName} Table Mappings`)}
            </BbotButton>
          )}
        </div>

        {invalidLocationSelection && (
          <BbotAlert className={"margin-bottom-2"} type={"error"} message={invalidLocationSelectionErrorMessage} />
        )}

        <BbotTable
          id={"locations-table"}
          data={locationsData}
          onAdd={() => {
            trackClickAddNewLocation();
            setShowAddLocationsModal(true);
          }}
          loading={loadingLocations}
          addButtonText={"Add New Location"}
          allowBulkEdit={true}
          allowBulkDeletion={true}
          showAddButton={userIsAdmin}
          title={"Locations"}
          manuallyFormattedColumns={locationsColumns}
          onRowSelectionChange={(selectedRowKeys) => {
            setSelectedLocationIds(selectedRowKeys);
          }}
          onBulkEdit={() => {
            trackClickEditLocation({ selected_location_ids: selectedLocationIds });
            setEditMode(true);
          }}
          onBulkDelete={() => {
            trackClickDeleteLocation({ selected_location_ids: selectedLocationIds });
            setShowDeleteLocationsModal(true);
          }}
          editButtonDisabled={invalidLocationSelection}
          pagination
        />
      </div>
    );
  };

  const externalUrlSelected = !!(selectedLocationIds.length === 1 && defaultLocationSettings.external_url);

  // Tried to use checkoutTabForm.getFieldsError, which I believe to be the correct way, but could not get it to work (seems to be an antd form issue).
  const checkIfCheckoutTabValid = async () => {
    try {
      await checkoutTabForm.validateFields();
      setCheckoutTabValid(true);
    } catch (errorInfo) {
      setCheckoutTabValid(errorInfo.errorFields.length === 0);
    }
  };

  const locationsEditorNew = (
    <BbotTabs
      tabPosition={"top"}
      renderTabBar={(props, DefaultTabBar) => (
        <BulkEditHeader
          defaultTabBarProps={props}
          DefaultTabBar={DefaultTabBar}
          header={"Editing Locations"}
          errorMessage={checkoutTabValid ? null : "Please fix an error on the Checkout tab."}
          showSave={
            checkoutTabValid &&
            Object.values(updatedLocationSettings).reduce(
              (sum, newChangesObj) => sum + Object.values(newChangesObj).length,
              0
            ) > 0
          }
          editObjectNames={locations
            .filter((location) => selectedLocationIds.includes(location.locationId))
            .map((location) => location.locationName)}
          onLinkButtonClick={() => {
            trackClickGoBackLocationEditor();
            setEditMode(false);
            setUpdatedLocationSettings({});
          }}
          onSave={() => {
            trackClickSaveLocationSettings();
            setShowSaveConfirmationModal(true);
          }}
        />
      )}
    >
      <Tabs.TabPane tab="General" key="general">
        <TabContainer>
          <GeneralTab
            fulfillmentMethods={fulfillmentMethodPrettyNames}
            kioskConfigs={kioskConfigs}
            defaultLocationSettings={defaultLocationSettings}
            updatedLocationSettings={updatedLocationSettings}
            updateLocationSettings={(updatedSettings, category = "properties") =>
              updateLocationSettings(updatedSettings, category)
            }
            updateCustomMenuProperties={updateCustomMenuProperties}
            removeChanges={(property, category = "properties") => removeChanges([property], category)}
            removeCustomMenuChanges={(properties) => removeCustomMenuPropertyChanges(properties)}
            bulkEditMode={selectedLocationIds.length > 1}
            selectedCustomer={selectedCustomer}
          />
        </TabContainer>
      </Tabs.TabPane>
      {!externalUrlSelected && (
        <>
          <Tabs.TabPane tab="Menus" key="menus">
            <TabContainer>
              <MenusTab
                defaultLocationSettings={defaultLocationSettings}
                updateLocationSettings={(updatedSettings) => updateLocationSettings(updatedSettings, "menus")}
                updateCustomMenuProperties={updateCustomMenuProperties}
                removeChanges={removeChanges}
                removeCustomMenuChanges={(properties) => removeCustomMenuPropertyChanges(properties)}
                bulkEditMode={selectedLocationIds.length > 1}
                menus={menus}
                locations={locations}
              />
            </TabContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Stations"} key={"stations"}>
            <TabContainer>
              <StationsTab
                defaultLocationSettings={defaultLocationSettings}
                updateLocationSettings={(updatedSettings) => updateLocationSettings(updatedSettings, "stations")}
                removeChanges={(property) => removeChanges([property], "stations")}
                bulkEditMode={selectedLocationIds.length > 1}
                stations={stations}
                locations={locations}
              />
            </TabContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Fulfillment"} key={"fulfillment"}>
            <TabContainer>
              <FulfillmentTab
                fulfillmentMethods={fulfillmentMethodPrettyNames}
                forceAllowOrderAhead={forceAllowOrderAhead}
                doordashLof={doordashLof}
                defaultLocationSettings={defaultLocationSettings}
                updatedLocationSettings={updatedLocationSettings}
                updateLocationSettings={(updatedSettings) => updateLocationSettings(updatedSettings, "properties")}
                removeChanges={(property) => removeChanges([property], "properties")}
                bulkEditMode={selectedLocationIds.length > 1}
                selectedLocations={locations.filter((location) => selectedLocationIds.includes(location.locationId))}
              />
            </TabContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Checkout"} key={"checkout"} data-test-id={"checkout-tab"}>
            <TabContainer>
              <Form
                form={checkoutTabForm}
                initialValues={{
                  minCartTotalCents: defaultLocationSettings?.minimum_paid_order_cents * 0.01 ?? 1,
                  maxPretaxPurchaseCents: defaultLocationSettings?.maximum_paid_order_cents * 0.01 ?? 0,
                  minPretaxPurchaseCents: defaultLocationSettings?.minimum_pretax_cents * 0.01 ?? 0,
                  forcedTipFraction: defaultLocationSettings?.forced_tip_fraction * 100 ?? 0,
                  patronTabAutoCloseDelayMinutes: defaultLocationSettings?.patron_tab_auto_close_delay_minutes ?? 180,
                }}
                onValuesChange={() => checkIfCheckoutTabValid()}
              >
                <CheckoutTab
                  selectedLocationIds={selectedLocationIds}
                  defaultLocationSettings={defaultLocationSettings}
                  updateLocationSettings={(updatedSettings) => updateLocationSettings(updatedSettings, "properties")}
                  updatedLocationSettings={updatedLocationSettings}
                  currentLocationSettings={Object.assign(defaultLocationSettings, updatedLocationSettings?.properties)}
                  updateCustomMenuProperties={updateCustomMenuProperties}
                  removeChanges={(property) => removeChanges(property, "properties")}
                  removeCustomMenuChanges={(properties) => removeCustomMenuPropertyChanges(properties)}
                  bulkEditMode={selectedLocationIds.length > 1}
                  formInstance={checkoutTabForm}
                  clearWarning={() => setCheckoutTabValid(true)}
                  userIsBbotAdmin={userIsBbotAdmin}
                />
              </Form>
            </TabContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Sharing"} key={"sharing"}>
            <TabContainer>
              <SharingTab
                selectedLocationIds={selectedLocationIds}
                defaultLocationSettings={defaultLocationSettings}
                updateLocationSettings={(updatedSettings) => updateLocationSettings(updatedSettings, "properties")}
                removeChanges={(property) => removeChanges(property, "properties")}
                removeCustomMenuChanges={(properties) => removeCustomMenuPropertyChanges(properties)}
                bulkEditMode={selectedLocationIds.length > 1}
              />
            </TabContainer>
          </Tabs.TabPane>
        </>
      )}
    </BbotTabs>
  );

  return (
    <TOPContainer>
      <div>
        <Breadcrumbs link={"edit-locations"} name={"Edit Locations"} />

        <div className={"margin-bottom-3"}>
          <h2>
            Manage Location Settings For <span className={"color-blue-5"}>{selectedCustomer?.customer_name}</span>
          </h2>
        </div>

        <div className={"margin-bottom-3"}>
          <div className={"color-blue-5"}>
            <p>
              {!editMode && (
                <span>
                  To edit location settings, first select at least one location in the table below. Then click the edit
                  button in the top right of the table.
                </span>
              )}
              <span>
                <br />
                The legacy location editor is available <a href={legacyLocationEditorUrl}>here</a>.
              </span>
            </p>
          </div>
          {<LocationEditorHelpLinks />}
        </div>

        {!editMode && locationsTable()}
      </div>

      {editMode && <SettingsEditorContainer>{locationsEditorNew}</SettingsEditorContainer>}

      <TableMappingModal
        show={showTableMappingsModal}
        onHideCallback={() => setShowTableMappingsModal(false)}
        onSaveCallback={() => getLocations()}
        locations={locations}
        selectedCustomer={selectedCustomer}
        posIntegration={posIntegration}
      />
      <SaveConfirmationModal
        show={showSaveConfirmationModal}
        onSaveCallback={() => {
          trackClickConfirmSaveLocationSettings();
          saveSettings();
        }}
        onHideCallback={() => setShowSaveConfirmationModal(false)}
        isSaving={isSaving}
        changes={updatedLocationSettings}
        menus={menus}
        stations={stations}
        fulfillmentMethodPrettyNames={fulfillmentMethodPrettyNames}
      />
      <AddLocationsModal
        fulfillmentMethodPrettyNames={fulfillmentMethodPrettyNames}
        show={showAddLocationsModal}
        onSubmitCallback={getLocations}
        onHideCallback={() => setShowAddLocationsModal(false)}
        locations={locations}
        selectedCustomer={selectedCustomer}
        userIsAdmin={userIsAdmin}
      />
      <DeleteLocationsModal
        show={showDeleteLocationsModal}
        onSubmitCallback={() => {
          setSelectedLocationIds([]);
          getLocations();
        }}
        onHideCallback={() => setShowDeleteLocationsModal(false)}
        locations={locations}
        selectedLocationIds={selectedLocationIds}
      />
    </TOPContainer>
  );
};

const TabContainer = styled.div`
  margin-left: 36px;
  margin-right: 36px;
`;

export default LocationEditor;
