import Widget from "components/customer-journey/widgets/Widget";
import { useEffect } from "react";
import "styles/customer-journey/JourneyTask.scss";
import { BbotAlert, TOPContainer } from "top-component-library";
import { getDoveTailAlertTextWithRedirect } from "../../pages/owner-app-dashboard/dovetail";

import { Colors, Text } from "@doordash/design-language";
import { trackViewJourneyTask } from "instrumentation/tracking/page-tracking-events";

import Banner from "@doordash/component-banner";
import styled from "styled-components";

export const TASK_WIDGET_INFO = {
  payment_and_billing_info: {
    title: "Getting Started",
    description:
      "Welcome to PreBot, your TOP onboarding helper! Please fill out the sections below before customizing your ordering site.",
  },
  connect_payment: {
    title: "Billing Method",
    description:
      "Connect a credit/debit card or bank account to cover Software Subscription fees and any other TOP expenses outlined in your contract. This information will be stored securely.",
  },
  billing_info: {
    title: "Billing Method",
    description:
      "Connect a credit/debit card or bank account to cover Software Subscription fees and any other TOP expenses outlined in your contract. This information will be stored securely.",
  },
  connect_stripe: {
    title: "Payouts",
    description:
      "Continue to Stripe and create an account to determine where payouts from your guests’ orders will be deposited. ",
  },
  invite_users: {
    title: "Invite Users (optional)",
    description: "Invite managers, accountants, and anyone else who needs access to this project.",
  },
  contact_info: {
    title: "Contact Info",
    description: "Add your contact information and address.",
  },
  primary_contact: {
    title: "Primary Contact",
    description:
      "This is the person who will be in charge of implementing and overseeing the usage of TOP at your venue.",
  },
  it_contact: {
    title: "IT Contact",
    description: "This is the person TOP should contact with hardware and network related concerns.",
  },
  venue_info: {
    title: "Venue Information",
    description:
      "This information will show to your guests on your ordering site and receipts. Integrated partners (such as third party delivery services) may also use this information to contact the venue.",
  },
  mailing_address: {
    title: "Mailing Address",
    description: "This address will be used to send you packages or mail.",
  },
  upload_menu: {
    title: "Add Menus",
    description:
      "Provide a link to your online menu or a PDF or image, and we’ll create your menu page for you. If you have menus that are available at different hours (i.e. breakfast, lunch, dinner), add them separately.",
  },
  tax_rate: {
    title: "Set Tax Rate",
    description: "This tax rate will be the default for all new menu items.",
  },
  brand_assets: {
    title: "Site Customization",
    description:
      "Add a logo, background, and banner to customize your site. Advanced customization will be available in your TOP Owner Portal.",
  },
  upload_logo: {
    title: "Upload Logo",
    description:
      "Upload the logo displayed on the menu page. PNG and SVG formats are supported. Recommend ratio of 1.5:1 or higher, at least 70px wide",
  },
  choose_ordering_pages: {
    title: "Service Operations",
    description: "Help us understand your onsite operations.",
  },
  ordering_page: {
    title: "Choose Ordering Page names",
    description: "Answer a few questions on your service model. This will help us finish setting up your account.",
  },
  t_and_c_agreement_task: {
    title: "Terms and Conditions",
  },
  terms_and_conditions: {
    title: "Terms and Conditions",
  },
};

const JourneyTask = ({
  task,
  updateTaskStatus,
  journey,
  userInfo,
  allowedCustomersById,
  isSelectedCustomerFlaggedForDovetail,
  selectedCustomer,
}) => {
  useEffect(() => {
    if (task && journey) {
      // Send tracking event to Segment
      trackViewJourneyTask({
        status: task.status,
        journey_id: journey.id,
        journey_task_id: task.id,
      });
    }
  }, [task, journey]);

  let widgetDisplay;
  if (task.widgets) {
    const widgetDisplayPositions = task.widgets.map((widget) => widget.display_position);
    const largestDisplayPosition = Math.max(...widgetDisplayPositions);
    const widgetsWithoutInvite = task.widgets.filter((widget) => widget.widget_type !== "invite_employees");
    const widgetsWithInviteLast = [
      ...widgetsWithoutInvite,
      {
        id: null,
        task_id: task.id,
        title: "",
        description: "",
        widget_type: "invite_employees",
        widget_config: {},
        widget_data: {},
        status: "completed",
        display_position: largestDisplayPosition + 1,
      },
    ];

    widgetDisplay = widgetsWithInviteLast
      .sort((widget1, widget2) => widget1.display_position - widget2.display_position)
      .map((widget, index) => (
        <div key={widget.id}>
          {isSelectedCustomerFlaggedForDovetail &&
          TASK_WIDGET_INFO[widget.widget_type] === TASK_WIDGET_INFO.connect_stripe ? (
            // If customer is flagged for dovetail and the task is connect_stripe, only show the title and alert (this feature is disabled when on dovetail)
            <div>
              <Text tag="h1" styles={Text.Styles.Title1} data-test-id="task-title">
                {TASK_WIDGET_INFO[widget.widget_type]?.title ?? widget.title}
              </Text>
              <BbotAlert
                className="margin-bottom-1 margin-top-1"
                message={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getDoveTailAlertTextWithRedirect(
                        "PreBot",
                        isSelectedCustomerFlaggedForDovetail,
                        selectedCustomer?.dd_store_id
                      ),
                    }}
                  />
                }
                type="info"
                showIcon
              />
            </div>
          ) : (
            // Otherwise, render task as normal
            <>
              {task.widgets.length > 1 && (
                <div>
                  <Text tag="h1" styles={Text.Styles.Title1} data-test-id="task-title">
                    {TASK_WIDGET_INFO[widget.widget_type]?.title ?? widget.title}
                  </Text>

                  <div className="margin-bottom-2">
                    <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
                      {TASK_WIDGET_INFO[widget.widget_type]?.description ?? widget.description}
                    </Text>
                  </div>
                </div>
              )}
              <Widget
                widget={widget}
                task={task}
                journey={journey}
                updateTaskStatus={updateTaskStatus}
                userInfo={userInfo}
                allowedCustomersById={allowedCustomersById}
              />
            </>
          )}

          {index < widgetsWithInviteLast.length - 1 && <hr className={"margin-y-3"} />}
        </div>
      ));
  }

  return (
    <Container className={"margin-bottom-4"} style={{ minHeight: "70vh" }}>
      <TitleContainer>
        <Text tag="h1" styles={Text.Styles.PageTitle1} data-test-id="task-title">
          {TASK_WIDGET_INFO[task.task_class]?.title ?? task.title}
        </Text>
        <Text styles={Text.Styles.Body1} color={Colors.TextSecondary}>
          {TASK_WIDGET_INFO[task.task_class]?.description ?? task.description}
        </Text>
      </TitleContainer>

      {task.task_class === "upload_menu" && (
        <div className="margin-bottom-3">
          <Banner body="If you are using a POS integration other than Omnivore, skip this step and notify your Account Manager." />
        </div>
      )}

      {widgetDisplay}
    </Container>
  );
};

const Container = styled(TOPContainer)`
  padding-top: 48px;
  padding-bottom: 48px;
  max-width: 800px;
`;

const TitleContainer = styled.div`
  margin-bottom: 48px;
`;
export default JourneyTask;
